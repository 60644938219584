

$(document).ready(function() {
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    if($('.work-images').length) {
        handle_image_upload_ajax("/work/photo/create/" + $('.work-images').data('work-id'), update_current_images_for_work);
        update_current_images_for_work();
    }
    if($('.admin-image-list').length) {
        handle_image_upload_ajax("/work/photo/create", update_current_images_general);
        update_current_images_general();
    }


    register_fancybox_listeners();
});

function register_fancybox_listeners() {
    $('.fancybox-thumbs').fancybox({
        prevEffect : 'none',
        nextEffect : 'none',
        closeBtn  : true,
        arrows    : false,
        nextClick : true,
        helpers : {
            thumbs : {
                width  : 50,
                height : 50
            }
        },
        'type':'image'
    });
}

function handle_image_upload_ajax(api_target, onSuccess) {
    var add_image_button = $('.add-image');
    $(add_image_button.data('shows')).hide();
    add_image_button.click(function(evt) {
        $($(evt.currentTarget).data('shows')).show();
        $($(evt.currentTarget).data('hides')).hide();
    });
    $('.image-save').click(function() {
        var data = new FormData();
        data.append('image', $('.new-image')[0].files[0]);
        data.append('description', $('.description').val());
        $.ajax({
            type: "POST",
            url: api_url + api_target,
            processData: false,
            contentType: false,
            data: data,
            success: onSuccess
        });
    });
}

function update_current_images_for_work() {
    $.ajax({
        type: "GET",
        url: api_url + "/work/photo/list/" + $('.work-images').data('work-id'),
        dataType: 'json',
        success: function (data) {
            $('.work-images .current-images').html("");
            $.each(data, function(index, element) {
                $('.work-images .current-images').append("" +
                    "<div class='image-wrapper "+( element.featured === '1' ? "featured" : "")+"' >" +
                    "   <img src='"+ image_url + "/" + element.id +"'><br>" +
                    "   <button class='make-featured-work-image' data-photo-id='"+element.id+"'><i class='glyphicon glyphicon-star'></i> Set Featured</button>" +
                    "   <button class='delete-work-image' data-photo-id='"+element.id+"'><i class='glyphicon glyphicon-trash'></i> Delete</button>" +
                    "</div>");
            });
            register_fancybox_listeners();
            $('.make-featured-work-image').click(function (clickEvent) {
                $.ajax({
                    type: "POST",
                    url: api_url + "/work/photo/setFeatured/"+ $('.work-images').data('work-id') + "/" + $(clickEvent.currentTarget).data('photo-id'),
                    success: function(featuredImageData) {
                        $('.image-wrapper').removeClass('featured');
                        $(clickEvent.currentTarget).parent('.image-wrapper').addClass('featured');
                    }
                });
            });
            $('.delete-work-image').click(function (clickEvent) {
                $.ajax({
                    type: "GET",
                    url: api_url + "/work/photo/delete/" + $(clickEvent.currentTarget).data('photo-id'),
                    success: function(deletePhotoData) {
                        update_current_images_for_work();
                    }
                });
            });
        }
    });
}

function update_current_images_general() {
    $('.admin-image-list ul').html("<li>Loading...</li>");
    $.ajax({
        type: "GET",
        url: api_url + "/work/photo/list",
        dataType: 'json',
        success: function (data) {
            $('.admin-image-list ul').html("");
            $.each(data, function(index, element) {
                $('.admin-image-list ul').append(""+
                    "<li class='image-work-id-"+element.work_id+"' data-work-id='"+element.work_id+"'>"+
                        "<div class='edit-wrapper'><a class='edit' href='" + element.link + "'><i class='fa fa-btn fa-pencil'></i><span class='sr-only'>Edit</span></a></div>" +
                        "<a href='" + element.url + "' rel='galleryA' class='fancybox-thumbs'><img src='"+element.url+"'></a>" +
                        element.title +
                        (element.edit_work_link ? " (<a href='"+element.edit_work_link+"'>edit work</a>)" : "") +
                        "<div class='delete-wrapper'><span class='delete' data-target='"+element.delete_photo_link+"'><i class='fa fa-btn fa-trash'></i><span class='sr-only'>Delete</span></span></div>" +
                    "</li>");
            });
            $('.admin-image-list span.delete').click(function(clickEvent) {
                $.ajax({
                    type: "GET",
                    url: $(clickEvent.currentTarget).data('target'),
                    success: function() {
                        $(clickEvent.target).closest('li').css('transition-duration', '1s').css('height',$(clickEvent.target).closest('li').height()+"px").css('max-width', '0px').css('opacity','0').css('overflow','hidden').css('margin','0');
                    }
                });
            });
            register_fancybox_listeners();
            $('.admin-image-list li').hover(function(evt) {
                $('.admin-image-list li').removeClass('sibling-selected');
                $('.image-work-id-'+$(evt.currentTarget).data('work-id')).addClass('sibling-selected');
            }, function() {
                $('.admin-image-list li').removeClass('sibling-selected');
            });
        }
    });
}